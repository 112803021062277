import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'

import { useLocation } from '@reach/router';
import { getMediaSeriesById } from '../services/api';
import { useState } from 'react';
import { SearchBar } from '../components/SearchBar';
import styled from 'styled-components';
import { Category } from '../components/Category';
import PageHeader from '../components/PageHeader'
import FooterBanner from '../components/DynamicBanner/FooterBanner'

// @ts-ignore


const PodcastPage = (props: any) => {

  //apiResult && apiResult.imageSrc ? <div style={{ background: `url: ${apiResult?.imageSrc}` }} /> : <div src="/images/placeholder.jpg" />
  return (
    <Page>
      <Container>
        <PageHeader title="Podcastit" />
        <Category title="" screenTitle="Uusimmat podcast-jaksot" description="" type={'list-podcast-files'} />
        <Category title="" screenTitle="Podcastit" description="" type={'list-podcast-series'} categoryIndex={1} />
        <FooterBanner />
      </Container>
    </Page>
  )
}

export default PodcastPage
